import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Spin, Button } from "antd";
import { ReportAPI } from '../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../Config";
import { useSelector } from "react-redux";

function PreDownloadedReportList() {

    const [loading, setLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const countryCode = useSelector(state => state.mainCountry);
    useEffect(async () => {
        setLoading(true)
        onGetReport()
    }, []);

    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100],
        downloadOptions: { filename: "preDownloadedReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'
            // build the json, this is one way to do it
            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v
                })
                result.push(temp)
                return result
            }, [])

            const fileName = `preDownloadedReport`
            const ws = utils.json_to_sheet(json)
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
            const data = new Blob([excelBuffer], { type: fileType })
            saveAs(data, fileName + fileExtension)
            // cancel default  CSV download from table
            return false
        }
    };

    const columns = [
        { name: "Report Name" },
        { name: "Start Date" },
        { name: "End Date" },
        { name: "Created Date" },
        { name: "Uploaded Date" },
        // { name: "Manual/System Generated" },
        { name: "Generated/Uploaded " },
        { name: "Uploaded By" },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button className="btn btn-primary" onClick={() => {
                            console.log(value, tableMeta)
                            window.location.href = `${Config.apiNodeURL}/${value}`;

                        }}>
                            Download
                        </Button>
                    )
                }
            }
        }
    ];

    const onGetReport = () => {
        const data={
            type:'generated',
            countryCode: countryCode
        }
        setLoading(true)
        ReportAPI.getPreDownloadedReport(data)
            .then(res => {
                let data = [];
                res.data.reports.forEach(report => {
                    let createDate = moment(report.updated_at).format('YYYY-MM-DD')
                    let updatedDate = moment(report.updated_at).format('YYYY-MM-DD')
                    // let isDownload = 'Manual'
                    // if (report.is_inception_to_till_date == 1) {
                    //     createDate = moment(report.updated_at).format('YYYY-MM-DD')
                    //     isDownload = 'System'
                    // }

                    if (report.userName) {
                        report.userName = report.userName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }
                    let resData = [
                        report.report_name,
                        report.start_date || '-',
                        report.end_date || '-',
                        report.is_generated_uploaded == 'generated' && createDate,
                        report.is_generated_uploaded == 'uploaded' && updatedDate,
                        report.is_generated_uploaded,
                        report.userName || '-',
                        report.report_path
                    ];

                    data = [resData, ...data]
                })
                setReports(data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <MUIDataTable
                        title={"Pre-Downloaded Report"}
                        data={reports}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}

export default PreDownloadedReportList
