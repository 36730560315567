// src/utils/formatAmount.js
export const formatAmount = {
    // Format with commas and optional decimal places
    withCommas: (amount, decimals = 2) => {
        if (!amount) return '0.00';
        
        try {
            // Convert to number if string
            const number = typeof amount === 'string' ? parseFloat(amount) : amount;
            
            // Handle invalid numbers
            if (isNaN(number)) return '0.00';
            
            // Format with specified decimal places and commas
            return number.toLocaleString('en-US', {
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            });
        } catch (error) {
            console.error('Error formatting amount:', error);
            return '0.00';
        }
    },

    // Format with currency symbol
    withCurrency: (amount, currency = 'USD', decimals = 2) => {
        if (!amount) return '$0.00';
        
        try {
            const number = typeof amount === 'string' ? parseFloat(amount) : amount;
            
            if (isNaN(number)) return '$0.00';
            
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                minimumFractionDigits: decimals,
                maximumFractionDigits: decimals
            }).format(number);
        } catch (error) {
            console.error('Error formatting currency:', error);
            return '$0.00';
        }
    },

    // Format to compact notation (e.g., 1K, 1M, 1B)
    compact: (amount) => {
        if (!amount) return '0';
        
        try {
            const number = typeof amount === 'string' ? parseFloat(amount) : amount;
            
            if (isNaN(number)) return '0';
            
            return new Intl.NumberFormat('en-US', {
                notation: 'compact',
                compactDisplay: 'short'
            }).format(number);
        } catch (error) {
            console.error('Error formatting compact number:', error);
            return '0';
        }
    },
};

// Optional: Create a custom hook for formatting
export const useFormatAmount = () => {
    return formatAmount;
};