import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import './App.scss';
import './assets/scss/custom.scss'

import { Layout, Menu } from "antd";
import Login from "./components/Login";
import Dashboard from "./components/pages/Dashboard";
import Quotes from "./components/pages/reports/Quotes";
import { getToken } from "./services/Token";
import AppLayout from "./components/AppLayout";
import AllPolicyReports from "./components/pages/reports/AllPolicyReports";
// import WrittenPremiumReport from "./components/pages/reports/WrittenPremiumReport";
import MotorComprehensiveReport from "./components/pages/reports/MotorComprehensiveReport";
import EarnedPremiumWithUnearned from "./components/pages/reports/EarnedPremiumWithUnearned";
import BookOfBusinessReport from "./components/pages/reports/BookOfBusinessReport";
import UserSales from "./components/pages/reports/UserSales";
import DetailedAgeAnalysis from "./components/pages/reports/DetailedAgeAnalysis";
import SummaryAgeAnalysis from "./components/pages/reports/SummaryAgeAnalysis";
import TransactionReportByProduct from "./components/pages/reports/TransactionReportByProduct";
import ActivationCancelledPolicyReport from "./components/pages/reports/ActivationCancelledPolicyReport";
import TransactionReport from "./components/pages/reports/TransactionReport";
import TransactionReportRealpay from "./components/pages/reports/TransactionReportRealpay";
import FailedTransactionReport from "./components/pages/reports/FailedTransactionReport";
import ClaimReport from "./components/pages/reports/ClaimReport";
import UserReportList from "./components/pages/reports/UserReportList";
import ReInsuranceRiskProfile from "./components/pages/reports/ReInsuranceRiskProfile";
import ReInsuranceClaimsProfile from "./components/pages/reports/ReInsuranceClaimsProfile";
import PolicyStatusReport from "./components/pages/reports/PolicyStatusReport";
import TransactionSummaryProductReport from "./components/pages/reports/TransactionSummaryProductReport";
import TransactionReportByBookingDate from "./components/pages/reports/TransactionReportByBookingDate";
import ReinsuranceRiskProfilesSummary from "./components/pages/reports/ReinsuranceRiskProfilesSummary";
import AgentReport from "./components/pages/reports/AgentReport";
import CustomerReport from "./components/pages/reports/CustomerReport";
import AnniversaryDateReport from "./components/pages/reports/AnniversaryDateReport";
import ClaimAsOnDate from "./components/pages/reports/ClaimAsOnDate";
import ClaimBordereaux from "./components/pages/reports/ClaimBordereaux";
import ClaimBordereauxOutstandingPayment from "./components/pages/reports/ClaimBordereauxOutstandingPayment";
import ClaimCoverageAllocationReport from "./components/pages/reports/ClaimCoverageAllocationReport";
import CustomerKYCReport from "./components/pages/reports/CustomerKYCReport";
import CustomerKYCReportV2 from "./components/pages/reports/CustomerKYCReportV2";
import CustomerKYCReportV3 from "./components/pages/reports/CustomerKYCReportV3";
import CustomerKYCReportV4 from "./components/pages/reports/CustomerKYCReportV4";
import KYCReportActivatedPolicies from "./components/pages/reports/KYCReportActivatedPolicies";
import VehiclePreinspctionReport from "./components/pages/reports/VehiclePreinspctionReport";
import VirtualBoxReport from "./components/pages/reports/VirtualBoxReport";
import AgentLoginReport from "./components/pages/reports/AgentLoginReport";
import PaymentReport from "./components/pages/reports/PaymentReport";
import AgentPolicyReport from "./components/pages/reports/AgentPolicyReport";
import StorePolicyReport from "./components/pages/reports/StorePolicyReport";
import CustomerKYCIDReport from "./components/pages/reports/CustomerKYCIDReport";
import ShowCashTransactions from "./components/pages/reports/ShowCashTransactions";
import OrangeMoneyReport from "./components/pages/reports/OrangeMoneyReport";
import CellphoneDevicePreInspectionReport from "./components/pages/reports/CellphoneDevicePreInspectionReport";
import CardExpiryReport from "./components/pages/reports/CardExpiryReport";
import UserPermission from "./components/pages/UserPermission";
import PaymentTransactionReport from "./components/pages/reports/PaymentTransactionReport";
import AgentLoginReportV2 from "./components/pages/reports/AgentLoginReportV2";
import UserReportListV2 from "./components/pages/reports/UserReportListV2";

import NewWrittenPremiumReport from "./components/pages/reports/NewWrittenPremiumReport";
import MonthlyCollection from "./components/pages/reports/MonthlyCollection";
import CustomerAllKYCDocumentsReport from "./components/pages/reports/CustomerAllKYCDocumentsReport";
import RenewalPolicy from "./components/pages/reports/RenewalPolicy";
import ViewVCSImport from "./components/pages/ViewVCSImport";
import PolicyCollectionReport from "./components/pages/reports/PolicyCollectionReport";
import CustomerDuplicateEmailReports from "./components/pages/reports/CustomerDuplicateEmailReports";
import RevioUpload from "./components/pages/reports/RevioUpload";
import MonthlyCollectionProductWiseReport from "./components/pages/reports/MonthlyCollectionProductWiseReport";
import MonthlyCollectionPaymentWiseReport from "./components/pages/reports/MonthlyCollectionPaymentWiseReport";
import ActivatedPoliciesKYCPendingReport from "./components/pages/reports/ActivatedPoliciesKYCPendingReport";
import PreDownloadedReportList from "./components/pages/PreDownloadedReportList";
import UploadReports from "./components/pages/UploadReports";
import BlacklistCustomerReport from "./components/pages/reports/BlacklistCustomerReport";
import VCSRecurringPaymentImport from "./components/pages/reports/VCSRecurringPaymentImport";
import VCSFirstPaymentImport from "./components/pages/reports/VCSFirstPaymentImport";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Console } from "./helper/Console";
import { ReportAPI } from "./apis/ReportAPI";
import CronSchedulerPage from "./components/pages/ScheduleCron";


function App(props) {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setCountryCode();
  }, []);

  const setCountryCode = () => {
    const countryCode = localStorage.getItem('country');
    Console(countryCode)
    if (countryCode) {
      props.saveCountry(countryCode);
    } else {
      props.saveCountry('bw')
      localStorage.setItem('country', 'bw');
    }
    setLoading(false)
  }

  const { Content } = Layout;
  return (
    <>
      {loading ?
        <h1 style={{ position: 'absolute', left: '50%', top: "50%", transform: "translate(-50%,-50%)" }}>Fetching country code...</h1>
        :
        <Router>
          <Route exact path="/" render={props => (
            !getToken() ? <Login /> : <Redirect to={{ pathname: '/dashboard' }} />
          )} />


          <div className="appcontent" >
            <Layout>
              {getToken() && <AppLayout />}
              <Content className="right_side_content">
                <div className="right_boxfill_content" >
                  <Route path="/dashboard" render={props => (
                    getToken() ? <Dashboard /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/quotes" render={props => (
                    getToken() ? <Quotes /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route exact path="/reports" render={props => (
                    getToken() ? <AllPolicyReports /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  {/* <Route path="/written-premium-report" render={props => (
                getToken() ? <WrittenPremiumReport /> : <Redirect to={{ pathname: '/' }} />
              )} /> */}

                  <Route path="/preDownloadedReportList" render={props => (
                    getToken() ? <PreDownloadedReportList /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/uploadedReports" render={props => (
                    getToken() ? <UploadReports /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/written-premium-report" render={props => (
                    getToken() ? <NewWrittenPremiumReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/motor-comprehensive-report" render={props => (
                    getToken() ? <MotorComprehensiveReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/earned-premium-with-unearned" render={props => (
                    getToken() ? <EarnedPremiumWithUnearned /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/book-of-business-report" render={props => (
                    getToken() ? <BookOfBusinessReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/user/userSales" render={props => (
                    getToken() ? <UserSales /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/detailed-age-analysis" render={props => (
                    getToken() ? <DetailedAgeAnalysis /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/summary-age-analysis" render={props => (
                    getToken() ? <SummaryAgeAnalysis /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/transaction-report-by-product" render={props => (
                    getToken() ? <TransactionReportByProduct /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/reports/policy-cancelled-report" render={props => (
                    getToken() ? <ActivationCancelledPolicyReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route exact path="/transaction-report" render={props => (
                    getToken() ? <TransactionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/transaction-report/Realpay" render={props => (
                    getToken() ? <TransactionReportRealpay /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/failed-transaction-report" render={props => (
                    getToken() ? <FailedTransactionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/claimReport" render={props => (
                    getToken() ? <ClaimReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/user-excel-report" render={props => (
                    getToken() ? <UserReportList /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/reInsuranceRiskProfile" render={props => (
                    getToken() ? <ReInsuranceRiskProfile /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/reInsuranceClaimsProfile" render={props => (
                    getToken() ? <ReInsuranceClaimsProfile /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/policy-status-report" render={props => (
                    getToken() ? <PolicyStatusReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/transaction-summary-product-report" render={props => (
                    getToken() ? <TransactionSummaryProductReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/transaction-report-by-booking-date" render={props => (
                    getToken() ? <TransactionReportByBookingDate /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/reinsuranceRiskProfilesSummary" render={props => (
                    getToken() ? <ReinsuranceRiskProfilesSummary /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/agentReport" render={props => (
                    getToken() ? <AgentReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerReport" render={props => (
                    getToken() ? <CustomerReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/anniversaryDateReport" render={props => (
                    getToken() ? <AnniversaryDateReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/claimAsOnDate" render={props => (
                    getToken() ? <ClaimAsOnDate /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/claimBordereaux" render={props => (
                    getToken() ? <ClaimBordereaux /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/claimBordereauxOutstandingPayment" render={props => (
                    getToken() ? <ClaimBordereauxOutstandingPayment /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/claimCoverageAllocationReport" render={props => (
                    getToken() ? <ClaimCoverageAllocationReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/vehiclePreinspctionReport" render={props => (
                    getToken() ? <VehiclePreinspctionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerKYCReport" render={props => (
                    getToken() ? <CustomerKYCReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerKYCReportV2" render={props => (
                    getToken() ? <CustomerKYCReportV2 /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerKYCReportV3" render={props => (
                    getToken() ? <CustomerKYCReportV3 /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerKYCReportV4" render={props => (
                    getToken() ? <CustomerKYCReportV4 /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/kycReportActivatedPolicies" render={props => (
                    getToken() ? <KYCReportActivatedPolicies /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/virtualBoxReport" render={props => (
                    getToken() ? <VirtualBoxReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/agentLoginReport" render={props => (
                    getToken() ? <AgentLoginReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/paymentReport" render={props => (
                    getToken() ? <PaymentReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/agentPolicyReport" render={props => (
                    getToken() ? <AgentPolicyReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/storePolicyReport" render={props => (
                    getToken() ? <StorePolicyReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/customerKYCIDReport" render={props => (
                    getToken() ? <CustomerKYCIDReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/showCashTransactions" render={props => (
                    getToken() ? <ShowCashTransactions /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/orangeMoneyReport" render={props => (
                    getToken() ? <OrangeMoneyReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/cellphoneDevicePreInspectionReport" render={props => (
                    getToken() ? <CellphoneDevicePreInspectionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/cardExpiry" render={props => (
                    getToken() ? <CardExpiryReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/user-permission" render={props => (
                    getToken() ? <UserPermission /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/payment-transaction" render={props => (
                    getToken() ? <PaymentTransactionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/agentLoginReport-v2" render={props => (
                    getToken() ? <AgentLoginReportV2 /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/report/user-report-list-v2" render={props => (
                    getToken() ? <UserReportListV2 /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/monthly-collection" render={props => (
                    getToken() ? <MonthlyCollection /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/customerAllKYCDocumentsReport" render={props => (
                    getToken() ? <CustomerAllKYCDocumentsReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/renewalPolicy" render={props => (
                    getToken() ? <RenewalPolicy /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/viewVCSImport" render={props => (
                    getToken() ? <ViewVCSImport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/policyCollectionReport" render={props => (
                    getToken() ? <PolicyCollectionReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/customerDuplicateEmailReports" render={props => (
                    getToken() ? <CustomerDuplicateEmailReports /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/revioUpload" render={props => (
                    getToken() ? <RevioUpload /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/monthlyCollectionProductWiseReport" render={props => (
                    getToken() ? <MonthlyCollectionProductWiseReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/monthlyCollectionPaymentWiseReport" render={props => (
                    getToken() ? <MonthlyCollectionPaymentWiseReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/activatedPoliciesKYCPendingReport" render={props => (
                    getToken() ? <ActivatedPoliciesKYCPendingReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/blacklistCustomerReport" render={props => (
                    getToken() ? <BlacklistCustomerReport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/vcsRecurringPaymentImport" render={props => (
                    getToken() ? <VCSRecurringPaymentImport /> : <Redirect to={{ pathname: '/' }} />
                  )} />

                  <Route path="/vcsFirstPaymentImport" render={props => (
                    getToken() ? <VCSFirstPaymentImport /> : <Redirect to={{ pathname: '/' }} />
                  )} />
                  <Route path="/scheduleCron" render={props => (
                    getToken() ? <CronSchedulerPage /> : <Redirect to={{ pathname: '/' }} />
                  )} />
                </div>
              </Content>
            </Layout>
          </div>


        </Router>
      }
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    saveCountry: (data) => {
      dispatch({ type: 'SET_MAIN_COUNTRY', payload: data })
    },
  }
}

export default connect(null, mapDispatchToProps)(App)