import React, { useState, useEffect } from 'react';
import { ClockCircleFilled, DeleteOutlined } from '@ant-design/icons';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { notification, Button, Form, Input } from 'antd';

const CronSchedulerPage = () => {
  const [form] = Form.useForm();
  const [scheduledJobs, setScheduledJobs] = useState([]);

  useEffect(() => {
    fetchScheduledJobs();
  }, []);

  const fetchScheduledJobs = async () => {
    try {
      const response = await fetch('/api/cron-jobs');
      if (!response.ok) throw new Error('Failed to fetch scheduled jobs');
      const jobs = await response.json();
      setScheduledJobs(jobs);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to fetch scheduled jobs',
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch('/api/schedule-cron', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to schedule cron job');
      }

      notification.success({
        message: 'Success',
        description: 'Cron job scheduled successfully',
      });
      form.resetFields();
      fetchScheduledJobs();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message,
      });
    }
  };

  const handleDelete = async (jobId) => {
    try {
      const response = await fetch(`/api/cron-jobs/${jobId}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete cron job');

      notification.success({
        message: 'Success',
        description: 'Cron job deleted successfully',
      });
      fetchScheduledJobs();
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to delete cron job',
      });
    }
  };

  return (
    <div style={{ maxWidth: 800, margin: '0 auto', padding: '20px' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Cron Job Scheduler</h1>

      <Form form={form} onFinish={handleSubmit} layout="vertical" style={{ marginBottom: '30px' }}>
        <Form.Item
          name="jobName"
          label="Job Name"
          rules={[
            { required: true, message: 'Please input schedule Name.' },
            { type: "string", message: 'Please input a valid schedule Name.' }
          ]}
        >
          <Input placeholder="Job name" />
        </Form.Item>
        <Form.Item
          name="cronExpression"
          label="Cron Expression"
          rules={[
            { required: true, message: 'Please input schedule Time.' },
            { type: "string", message: 'Please input a valid schedule Time.' }
          ]}
        >
          <Input placeholder="Enter cron expression (e.g., */10 * * * *)" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">Schedule Job</Button>
        </Form.Item>
      </Form>

      <h2 style={{ fontSize: '18px', fontWeight: 'semibold', marginBottom: '10px' }}>Scheduled Jobs</h2>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Job Name</TableCell>
            <TableCell>Cron Expression</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scheduledJobs.map((job) => (
            <TableRow key={job.id}>
              <TableCell>{job.name}</TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ClockCircleFilled style={{ marginRight: '8px' }} />
                  {job.cronExpression}
                </div>
              </TableCell>
              <TableCell>
                <Button
                  type="primary"
                  danger
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(job.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default CronSchedulerPage;