import React, { Fragment, useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, Input, DatePicker, Select, Button, Spin, notification } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'
import { Config } from "../../../Config";
import { useSelector } from "react-redux";
import { Token } from "../../../services/Token";

const { Option } = Select;
const { RangePicker } = DatePicker;
export default function AllPolicyReports() {
    const countryCode = useSelector(state => state.mainCountry)
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);

    const [products, setProducts] = useState([]);
    const [productPlans, setProductPlans] = useState([]);
    const [agents, setAgents] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [genratedDateRange, setGenratedDateRange] = useState([]);

    const [fPolicyStatus, setFPolicyStatus] = useState('-1');
    const [fProductID, setFProductID] = useState('-1');
    const [fProductPlanID, setFProductPlanID] = useState('-1');
    const [fPaymnetStatus, setFPaymnetStatus] = useState('-1');
    const [fPreminumValue, setFPreminumValue] = useState('');
    const [fAgentID, setFAgentID] = useState('-1');
    const [fAgencyID, setFAgencyID] = useState('-1');
    const [fdateRange, setFdateRange] = useState([]);
    const [fPolicyNumber, setFPolicyNumber] = useState('');
    const [fPreDownloadReportID, setPreDownloadReportID] = useState('-1');

    const [search, setSearch] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const userRole = Token.getUserRole();

    useEffect(async () => {
        setLoading(true)
        await ReportAPI.fetchFilterDataForReport(countryCode, userRole)
            .then(res => {
                setProducts(res.data.products);
                setProductPlans(res.data.productPlans);
                setAgents(res.data.policyAgents);
                setAgencies(res.data.policyAgencies);
                setGenratedDateRange(res.data.genratedDateRange);

                setLoading(false)
            }).catch(error => {
                setLoading(false)
            });

        // await onClickFilter();
        // loadData();
    }, []);


    const options = {
        selectableRows: 'none',
        // selectableRows: false, // <===== will turn off checkboxes in rows
        download: false,
        display: false,
        sort: false,
        empty: false,
        filter: false,
        // download: false,
        responsive: "scroll",
        // resizableColumns: true,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 500],
        downloadOptions: { filename: "PolicyList.csv", separator: "," },
        elevation: 6,
        onChangeRowsPerPage: (numberOfRows) => {
            loadData(numberOfRows, page, search)
        },
        serverSide: true,
        count: Math.ceil(count),
        page: page,
        onTableChange: (action, tableState) => {
            switch (action) {
                case 'changePage':
                    loadData(limit, tableState.page, search)
                    break;
                case 'search':
                    loadData(limit, tableState.page, tableState.searchText)
                    break;
            }
        }
    };

    const columns = [
        "Policy No.",
        "Customer Name",
        "Customer Email",
        "Contact Number",
        "Customer Address",
        "Customer City",
        "Customer State",
        "Agent Name",
        "Agency Name",
        "Branch",
        "Product",
        "Product Plan",
        "Store Name",
        "Vehicle Plate",
        "Annual Premium",
        "Premium Value",
        "KYC Status",
        "Payment Method",
        "Payment Frequency",
        "Payment Status",
        "Reference No.",
        "Policy Status",
        "Is Virtual Box",
        // "Card Expiry Year",
        // "Card Expiry Month",
        "Billing Date",
        "Policy Inception Date",
        "Policy Inception Time",
        "Created Date",
        "Created Time",
        // "Cancelled Date"
    ];

    const loadData = async (limit, page, search) => {

        const data = {
            search: search,
            limit: limit,
            page: page,
            fPolicyStatus: fPolicyStatus,
            fProductID: fProductID,
            fProductPlanID: fProductPlanID,
            fPaymnetStatus: fPaymnetStatus,
            fPreminumValue: fPreminumValue,
            fAgentID: fAgentID,
            fAgencyID: fAgencyID,
            fdateRange: fdateRange,
            fPolicyNumber: fPolicyNumber,
            isDownload: 'no',
            countryCode
        }

        setSearch(search)
        setLimit(limit)
        setPage(page)
        setLoading(true)

        ReportAPI.fetchFilterPolicyReports(data)
            .then(resApiData => {

                let data = [];
                const policies = resApiData.policies

                if (policies.length > 0) {
                    setCount(policies[0].totalCount)
                }

                policies.forEach((policy, i) => {

                    if (policy.customerName !== 'N/A' && policy.customerName !== null) {
                        policy.customerName = policy.customerName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.agentName !== 'N/A' && policy.agentName !== null) {
                        policy.agentName = policy.agentName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.productName !== 'N/A' && policy.productName !== null) {
                        policy.productName = policy.productName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.productPlanName !== 'N/A' && policy.productPlanName !== null) {
                        policy.productPlanName = policy.productPlanName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    if (policy.storeName !== 'N/A' && policy.storeName !== null) {
                        policy.storeName = policy.storeName.toLowerCase().replace(/\b[a-z]/g, function (letra) {
                            return letra.toUpperCase();
                        });
                    }

                    let paymentStatus = "Payment Status Not Found";
                    let referenceNumber = "Payment reference not generated";
                    let paymentMethod = "";

                    if (resApiData.paymentTransactionsRecords[policy.policyNumber]) {
                        if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "A") {
                            paymentStatus = "Payment Pending";
                        } else if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "P") {
                            paymentStatus = "Payment Processing";
                        } else if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "S" || resApiData.paymentTransactionsRecords[policy.policyNumber].status == "1") {
                            paymentStatus = "Payment Successfull";
                        } else if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "Success" || resApiData.paymentTransactionsRecords[policy.policyNumber].status === 'SUCCESS') {
                            paymentStatus = "Payment Successfull";
                        } else if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "PROCESSING") {
                            paymentStatus = "Payment Processing";
                        } else if (resApiData.paymentTransactionsRecords[policy.policyNumber].status === "F" || resApiData.paymentTransactionsRecords[policy.policyNumber].status === 'Failed') {
                            paymentStatus = "Payment Failed";
                        } else {
                            paymentStatus = "Payment Status Not Found";
                        }

                        referenceNumber = resApiData.paymentTransactionsRecords[policy.policyNumber].referenceNumber;
                        if (!resApiData.paymentTransactionsRecords[policy.policyNumber].referenceNumber) {
                            referenceNumber = "Payment reference not generated";
                        }
                        paymentMethod = resApiData.paymentTransactionsRecords[policy.policyNumber].paymentMethod === 'orangeMoney' ? 'Orange USSD' : resApiData.paymentTransactionsRecords[policy.policyNumber].paymentMethod;
                    }

                    if (
                        policy.policyStatus == 'Activated'
                        && paymentStatus == 'Payment Successfull'
                        && (policy.customer_kyc_status == "Unchecked" || policy.customer_kyc_status == "Unapprove")
                    ) {
                        policy.kycStatus = 'Deactivated- kyc pending';
                    }

                    let premiumFreq = 'Monthly';
                    if (policy.premium_freq == 1) {
                        premiumFreq = 'Monthly'
                    } else if (policy.premium_freq == 2) {
                        premiumFreq = '3 Installment'
                    } else if (policy.premium_freq == 3) {
                        premiumFreq = 'Annual'
                    }

                    let existingPremium = policy.premium;
                    let existingPremFreq = policy.premium_freq;
                    let freqArr = { "1": "monthly", "2": "3_inst", "3": "annual" }
                    let premiumArr = [];

                    let monthlyPrem;
                    if (existingPremFreq == "1") {
                        monthlyPrem = existingPremium;
                    } else {
                        monthlyPrem = getMonthlyPrem(existingPremFreq, existingPremium);
                    }

                    for (const key in freqArr) {
                        if (key == existingPremFreq) {
                            premiumArr[freqArr[key]] = parseFloat(existingPremium)
                        } else {
                            let newPremium;
                            switch (parseInt(key)) {
                                case 1:
                                    newPremium = monthlyPrem;
                                    premiumArr['monthly'] = newPremium;
                                    break;

                                case 2:
                                    newPremium = monthlyPrem * 4;
                                    premiumArr['3_inst'] = newPremium;
                                    break;

                                case 3:
                                    newPremium = (monthlyPrem * 12) / 1.08;
                                    premiumArr['annual'] = newPremium;
                                    break;

                                default:
                                    break;
                            }
                        }
                    }

                    let annual_premium = null
                    if (premiumArr['annual'] != undefined) {
                        annual_premium = premiumArr['annual']
                    }

                    if (policy.policyNumber != 3 && (existingPremFreq == 1 || existingPremFreq == null)) {
                        annual_premium = parseFloat(policy.premium) * 12
                    }

                    if (policy.cancelled_date == null) {
                        policy.cancelled_date = policy.customer_feedback_created_at;
                    }

                    let resData = [
                        policy.policyNumber,
                        policy.customerName,
                        policy.email,
                        policy.contactNumber,
                        policy.address,
                        policy.city,
                        policy.state,
                        policy.agentName,
                        policy.agencyName ? policy.agencyName : '-',
                        policy.branchName,
                        policy.productName,
                        policy.productPlanName,
                        policy.storeName,
                        policy.vehiclePlate,
                        annual_premium ? parseFloat(annual_premium).toFixed(2) : '-',
                        policy.premium ? parseFloat(policy.premium).toFixed(2) : '-',
                        policy.kycStatus,
                        paymentMethod,
                        premiumFreq,
                        paymentStatus,
                        referenceNumber,
                        policy.policyStatus,
                        policy.isVirtualBox,
                        policy.billingStartDate !== null ? moment(policy.billingStartDate).format('DD-MM-YYYY') : '-',
                        policy.created_at ? moment(policy.created_at).format('DD-MM-YYYY') : '-',
                        policy.created_at ? moment(policy.created_at).format('HH:mm:ss') : '-',
                        moment(policy.created_at).format('DD-MM-YYYY'),
                        moment(policy.created_at).format('HH:mm:ss')
                    ];
                    data = [resData, ...data]
                });

                setLoading(false)
                setPolicies(data)
            }).catch(error => {
                setLoading(false)
            });
    }

    const onClickDownload = async () => {
        console.log(countryCode)
        const reqData = {
            fPolicyStatus: fPolicyStatus,
            fProductID: fProductID,
            fProductPlanID: fProductPlanID,
            fPaymnetStatus: fPaymnetStatus,
            fPreminumValue: fPreminumValue,
            fAgentID: fAgentID,
            fAgencyID: fAgencyID,
            fdateRange: fdateRange,
            fPolicyNumber: fPolicyNumber,
            isDownload: 'yes',
            countryCode
        }
        setLoading(true);

        await ReportAPI.fetchFilterPolicyReports(reqData)
            .then(resApiData => {
                window.location.href = `${Config.apiNodeURL}/${resApiData.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickPreDownload = async () => {
        if (fPreDownloadReportID == '-1') {
            return notification.error({ message: "Please select the Pre Generated Report." });
        }
        const reqData = {
            fPreDownloadReportID,
            isPreDownload: 'yes'
        }

        setLoading(true);

        await ReportAPI.fetchFilterPolicyReports(reqData)
            .then(resApiData => {
                window.location.href = `${Config.apiNodeURL}/${resApiData.filePath}`;

                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }

    const onClickSerach = () => {
        loadData(limit, 0, search)
    }

    const getMonthlyPrem = (existingPremFreq, existingPremium) => {
        if (existingPremFreq == 2) {
            return (existingPremium / 4) * 1.08;
        }
        if (existingPremFreq == 3) {
            return (existingPremium / 12) * 1.08;
        }
    }

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wapper_content reports_wapper_content">
                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Pre-Generated Report</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setPreDownloadReportID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content"
                                >
                                    <Option value="-1">Select Report Range</Option>
                                    {
                                        genratedDateRange?.map((dr) => {
                                            let isInceptionReport = ''
                                            if (dr.is_inception_to_till_date == 1) {
                                                isInceptionReport = '(Inception to Till Date)'
                                            }

                                            return (
                                                <Option key={Math.random()} value={dr.id}>{`${dr.start_date} - ${dr.end_date} ${isInceptionReport}`}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={onClickPreDownload} className="btn btn-success">Download Generated</Button>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="button_search_content">
                                    <Button onClick={() => {
                                        genratedDateRange?.map((dr) => {
                                            if (dr.is_inception_to_till_date == 1) {
                                                window.location.href = `${Config.apiNodeURL}/${dr.report_path}`;
                                            }
                                        })
                                    }} className="btn btn-primary">Download From Inception to Till Date</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="form_wapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Policy Status</label>
                                <Select style={{ width: '100%' }}
                                    onChange={(val) => setFPolicyStatus(val)}
                                    defaultValue="-1"
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="0">Deactivated</Option>
                                    <Option value="1">Activated</Option>
                                    <Option value="2">Cancel</Option>
                                    <Option value="3">Expired</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product </label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        products.map((product) => {
                                            return (
                                                <Option key={Math.random()} value={product.id}>{product.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Product Plan</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFProductPlanID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    placeholder="All" className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        productPlans.map((productPlan) => {
                                            return (
                                                <Option key={Math.random()} value={productPlan.id}>{productPlan.name}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By payment status</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFPaymnetStatus(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    <Option value="SUCCESS">Success</Option>
                                    <Option value="PENDING">Pending</Option>
                                    <Option value="FAILED">Failed</Option>
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By premium value</label>
                                <Input
                                    placeholder="Please enter premium value"
                                    onChange={(e) => setFPreminumValue(e.target.value)}
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agent</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgentID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agents.map((agent) => {
                                            return (
                                                <Option key={Math.random()} value={agent.id}>{`${agent.firstName} ${agent.lastName}`}</Option>
                                            )
                                        })
                                    }
                                </Select>

                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By Agency</label>
                                <Select showSearch style={{ width: '100%' }}
                                    onChange={(val) => setFAgencyID(val)}
                                    defaultValue="-1"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                                    className="select-content">
                                    <Option value="-1">All</Option>
                                    {
                                        agencies.map((agency) => {
                                            return (
                                                <Option key={Math.random()} value={agency.id}>{agency.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label >Filter By date range </label>
                                <RangePicker 
        allowClear
        format="YYYY-MM-DD"
        onChange={(value, dateString) => {
            if (value) {
                // Ensure the start date is not before 2024 for non-admin users
                const adjustedValue = value.map(date => 
                    userRole !== 'Admin' && date && date.isBefore('2024-01-01') 
                        ? moment('2024-01-01') 
                        : date
                );
                setFdateRange(adjustedValue.map(date => date ? date.format('YYYY-MM-DD') : ''));
            } else {
                setFdateRange([]);
            }
        }}
        disabledDate={(current) => {
            // Disable dates before 2024 for non-admin users
            return userRole !== 'Admin' && current && current < moment('2024-01-01').startOf('day');
        }}
    />
                            </Col>

                            <Col xs={24} lg={5} className="select_content ">
                                <label>Please enter policy number</label>
                                <div className="">
                                    <Input placeholder="Please enter policy number" onChange={(e) => setFPolicyNumber(e.target.value)} />
                                </div>
                            </Col>

                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickSerach} className="btn btn-primary">Search</Button>
                                </div>
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickDownload} className="btn btn-primary">Download</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <MUIDataTable
                        title={"Policy List"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    )
}
