import React, { Fragment, useState } from "react";
import MUIDataTable from "mui-datatables";
import { Col, Row, DatePicker, Button, Spin } from "antd";
import { ReportAPI } from '../../../apis/ReportAPI';
import moment from "moment";
import {formatAmount} from "../../../helper/Formatter";
import { saveAs } from 'file-saver'
import { write, utils } from 'xlsx'

const { RangePicker } = DatePicker;

export default function NewWrittenPremiumReport() {

    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [fdateRange, setFdateRange] = useState([]);
    
    const options = {
        selectableRows: 'none',
        display: false,
        sort: false,
        empty: false,
        filter: false,
        print: false,
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 100, 200],
        downloadOptions: { filename: "writtenPremiumReport.csv", separator: "," },
        elevation: 6,
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
            const fileExtension = '.xlsx'

            const json = values.reduce((result, val) => {
                const temp = {}
                val.data.forEach((v, idx) => {
                    temp[columns[idx].name] = v;
                });
                result.push(temp);
                return result;
            }, []);

            const fileName = `writtenPremiumReport`
            const ws = utils.json_to_sheet(json);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, fileName + fileExtension);

            return false;
        }
    };

    const columns = [
        "Policy No.",
        "Term Start",
        "Term End",
        "Product Name",
        "Insured Name",
        "Transaction Type",
        "Risk Name",
        "Motor Desc",
        "Make",
        "Coverage Codes",
        "Total Sum Insured",
        "Total Premium",
        "NETRETENTION SI",
"NETRETENTIO",
"QUOTASHARING SI",
"QUOTASHARING",
"SURPLUS SI",
"SURPLUS",
"FACULTATIVE SI",
"FACULTATIVE",
"Treaty Name",
"Regulatory Mapping Name",
"FAC PLACEMENT NO",
"RISK BAND",
"MAX TRANS YES / NO",
"Booking Date",
"Policy Status",
"Invoice No.",
];

    const onClickFilter = () => {
        setLoading(true);
        const data = { fdateRange: fdateRange };
        ReportAPI.fetchFilterNewWrittenPremiumReports(data)
        .then(res => {
            const formattedData = res.data.policies.map(policy => {
                // Check if calculated_premium is an object with a data array
                // let calculatedPremiumSum;
                // if (policy.calculated_premium && Array.isArray(policy.calculated_premium.data)) {
                //     calculatedPremiumSum = policy.calculated_premium.data.reduce((sum, value) => sum + value, 0);
                // } else {
                //     calculatedPremiumSum = policy.calculated_premium || "N/A";
                // }
                let netretntion = policy.sum_assured;
                let risk_band;
                if (netretntion >= 0 && netretntion <= 250000) {
                    risk_band = '0 To 250000';
                } else if (netretntion >= 250001 && netretntion <= 500000) {
                    risk_band = '250001 To 500000';
                } else if (netretntion >= 500001 && netretntion <= 1000000) {
                    risk_band = '500001 To 1000000';
                } else if (netretntion >= 1000001 && netretntion <= 5000000) {
                    risk_band = '1000001 To 5000000';
                }

                let transType = policy.policy_status === 2 ? "Cancelled" : 
                            moment(policy?.policy_created_at).isAfter(moment('2020-07-01')) ? "New Business" : "Renew";
                return [
                    policy.policyNumber,
                    policy.term_start ? moment(policy.term_start).format('DD-MM-YYYY') : "N/A",
                    policy.term_end ? moment(policy.term_end).format('DD-MM-YYYY') : "N/A",
                    policy.product_name,
                    policy.insured_name,
                    transType,
                    policy.address,
                    policy.registration_nos,
                    `${policy.makes}/${policy.models}`,
                    policy.coverage_codes || "N/A",
                    formatAmount.withCommas(policy.sum_insured),
                    formatAmount.withCommas(policy.policy_ledger_written_premium),
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    '-',
                    risk_band,
                    '-',
                    policy.accounting_date ? moment(policy.accounting_date).format('DD-MM-YYYY') : "N/A",
                    policy.policy_status === 2 ? "Cancelled" : "Active",
                    policy.invoice_no,
                    // calculatedPremiumSum,
                    
                ];
            });
                setLoading(false);
                setPolicies(formattedData);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error fetching report data:', error);
            });
    };

    return (
        <Fragment>
            <Spin spinning={loading} delay={500}>
                <div className="wrapper_content reports_wrapper_content">
                    <div className="form_wrapper_content">
                        <Row>
                            <Col xs={24} lg={5} className="select_content">
                                <label>Filter By date range</label>
                                <RangePicker
                                    allowClear
                                    format="YYYY-MM-DD"
                                    onChange={(value, dateString) => {
                                        setFdateRange(dateString || []);
                                    }}
                                />
                            </Col>
                            <Col xs={24} lg={5} className="select_content">
                                <label>&nbsp;</label>
                                <div className="button_search_content">
                                    <Button onClick={onClickFilter} className="btn btn-primary">Search</Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <MUIDataTable
                        title={"Written Premium Report"}
                        data={policies}
                        columns={columns}
                        options={options}
                    />
                </div>
            </Spin>
        </Fragment>
    );
}
